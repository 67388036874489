import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
		<div className="section" style={{padding: `0.5rem 1rem`}}>
			<div className="container">
        <div className="columns">
					<div className="column">
						<h1 className="title is-6">ページが見つかりませんでした</h1>
            <p>申し訳ありません。アクセスしようとしたページは存在しておりません。</p>
					</div>
				</div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
